
<template>
  <div class="container">
    <div class="activityContent">
      <div class="tit">{{ name }}</div>
      <div class="info">邀请你加入自闭症支持大家庭，免费学习</div>
      <div class="info">自闭症相关课程！</div>
    </div>
    <div class="inputContent">
      <div class="phone">
        <div class="phoneAre">+86</div>
        <input
          class="phoneInput"
          v-model="phoneNum"
          maxlength="11"
          v-number-only
          placeholder="请输入手机号码"
        />
      </div>
      <div class="phone code">
        <input
          class="codeInput"
          v-model="codeNum"
          type="telephone"
          pattern="[0-9]*"
          v-number-only
          maxlength="6"
          placeholder="请输入验证码"
        />
        <div class="codeBtn" @click="sendMsgCodeAction()">{{ yzmtext }}</div>
      </div>
      <div class="joinBtn" @click="joinFamilyAction()">立即加入</div>
    </div>
    <img src="../assets/img/img_share_logo_m.png" alt="" class="topImg" />
    <div class="footer">
      <div class="left">
        <img src="../assets/img/img_share_logo_s.png" alt="" class="iconImg" />
        <div class="info">
          <div class="name">星家人APP</div>
          <div class="des">自闭症全生命周期支持</div>
        </div>
      </div>
      <div class="downloadBtn" @click="downloadAction()">下载</div>
    </div>
  </div>
</template>

<script>

import {Toast} from 'vant';
import Vue from 'vue';
Vue.use(Toast);

export default {
  name: "sharePage",
  data() {
    return {
      name: "",
      invitedId: this.$route.query.id,
      phoneNum: "",
      codeNum: "",
      btnBool: true,
      yzmtext: "获取验证码",
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {//获取分享人的昵称
      this.$get("getInviteNickName", {
        invitedId: this.invitedId,
      })
        .then((res) => {
          if (res.code == 200) {
            this.name = res.data;
          }
        })
        .catch(() => {
          // console.log(res)
        });
    },
    getyzm() {
      if (this.btnBool == true) {
        this.btnBool = false;
        let i = 60;
        let timer = setInterval(() => {
          this.yzmtext = i + "s";
          i--;
          if (i < 0) {
            this.btnBool = true;
            this.yzmtext = "获取验证码";
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    sendMsgCodeAction() {//发送验证码
      if (this.cheackPhoneValue() == false) {
        this.$toast("请输入正确的手机号");
        return;
      }
      this.$post("sendCode", {
        type: 3,
        phone: this.phoneNum,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$toast('发送验证码成功');
            this.getyzm();
          }else{
            this.$toast(res.msg);
          }
        })
        .catch(() => {});
    },
    joinFamilyAction() {//加入星家人
      this.$post("join", {
        phone: this.phoneNum,
        code: this.codeNum,
        inviteId: this.invitedId,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$toast("加入星家人成功");
          } else {
            this.$toast(res.msg);
          }
        })
        .catch(() => {});
    },

    cheackPhoneValue() {
      if (this.phoneNum.length < 11 || this.phoneNum.length > 11) {
        return false;
      }
      return /^1\d{10}$/i.test(this.phoneNum);
    },
    downloadAction() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        window.location.href = "https://apps.apple.com/cn/app/%E6%98%9F%E5%AE%B6%E4%BA%BA/id1582745268?from=groupmessage&isappinstalled=0";
      } else {
        window.location.href = "https://xingjiaren-api.hailiangedu.com/app/download";
      }
    },
  },
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
};
</script>
<style>
.container {
  position: fixed;
  background: #f4f4f6;
  width: 100%;
  height: 100%;
}
.container .activityContent {
  height: auto;
}
.container .activityContent .tit {
  padding-top: 30px;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.7;
  text-align: center;
  color: #303331;
}
.container .activityContent .info {
  color: #909399;
  text-align: center;
  line-height: 1.4;
  font-size: 15px;
}
.container .inputContent {
  background: #fff;
  margin-top: 18px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 12px;
  padding: 16px 24px 36px 24px;
}
.container .inputContent .phone {
  height: 60px;
  position: relative;
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #e8eaec;
}
.container .inputContent .phone .phoneAre {
  color: #bfc2cc;
  font-size: 20px;
  margin-top: 26px;
}
.container .inputContent .phone .phoneInput {
  width: 240px;
  border: none;
  font-size: 16px;
  margin-top: 18px;
}
.container .inputContent .phone .phoneInput::-webkit-input-placeholder {
  color: #bfc2cc;
}

.container .inputContent .code {
  justify-content: space-between;
}
.container .inputContent .code .codeInput {
  width: 150px;
  border: none;
  font-size: 16px;
  margin-top: 18px;
}
.container .inputContent .code .codeInput::-webkit-input-placeholder {
  color: #bfc2cc;
}
.container .inputContent .code .codeBtn {
  width: 80px;
  height: 20px;
  color: #3a62e4;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  margin-top: 30px;
}
.container .inputContent .joinBtn {
  background-color: #3a62e4;
  color: #fff;
  text-align: center;
  line-height: 46px; /* 高度和控件高度一致就可以实现居中*/
  height: 46px;
  width: 100%;
  border-radius: 23px;
  margin-top: 42px;
}
.container .topImg {
  margin-top: 47px;
  margin-left: 98px;
  margin-right: 98px;
  height: 28px;
}
.container .footer {
  background-color: #273148;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: 40px;
  bottom: 0;
  width: 100%;
}
.container .footer .left {
  display: flex;
}
.container .footer .iconImg {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  margin-left: 22px;
}
.container .footer .info {
  color: #fff;
}
.container .footer .info .name {
  font-size: 17px;
}
.container .footer .info .des {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.container .footer .downloadBtn {
  width: 86px;
  height: 34px;
  border-radius: 17px;
  background-color: #3a62e4;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 34px;
  margin-right: 22px;
}

.content {
  font-size: 26px;
  color: #666;
  margin-top: 30px;
}
</style>